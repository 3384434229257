import request from '@/api/request.js'



/**
 * 查询审批人 ----- 根据流程节点类型（list） 查每个节点的审批人
 */
export function selectUser() {
  return request({
    url: 'oa/org/selectUser',
    method: 'POST',
  })
}

export function selectCoachORDeptByName(param) {
  return request({
    url: 'oa/org/selectCoachORDeptByName',
    method: 'get',
    params: param
  })
}
// 查询部门和部门下的教练 --- 树结构
export function selectCoachAndDeptTree(param) {
  return request({
    url: 'oa/org/selectCoachAndDeptTree',
    method: 'get',
    params: param
  })
}
// 查询部门 --- 树结构
export function selectDeptTree(param) {
  return request({
    url: 'oa/org/tree/selectDeptTree',
    method: 'get',
    params: param
  })
}
// 查询教练
export function selectCoach(param) {
  return request({
    url: 'oa/org/selectCoach',
    method: 'get',
    params: param
  })
}

export function getUserByName(param) {
  return request({
    url: 'oa/org/selectCoachORDeptByName',
    method: 'get',
    params: param
  })
}
/**
 * 查询审批人 ----- 根据流程节点类型（list） 查每个节点的审批人
 */
export function selectApprover(query) {
  return request({
    url: 'oa/org/selectApprover',
    method: 'POST',
    data: query,
  })
}


/**
 * 查校区
 */
export function selectCampus(param) {
  return request({
    url: 'oa/org/selectCampus',
    method: 'get',
    params: param
  })
}


// 查询部门和部门下的教练 架构树   ------- 弃用-----------
export function getOrgTree(param) {
  return request({
    url: 'oa/org/selectCoachAndDeptTree',
    method: 'get',
    params: param
  })
}

export default {
  getOrgTree
}
